import { createStore } from 'redux'
import CryptoJS from 'crypto-js';
var decryptedData=null
let secrete = localStorage.getItem('secrete');
  if(secrete){
    var bytes  = CryptoJS.AES.decrypt(secrete, process.env.REACT_APP_SALT_SECRETE);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
  }
const initialState = {
  sidebarShow: true,
  auth: !!decryptedData,
  userData:decryptedData
}

const changeState = (state = initialState, { type, ...rest }) => {
  
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
